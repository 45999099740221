import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import AuctionGridItem from './auctionGridItem';
import Loading from './loading';
import SearchIcon from './svg/searchIcon';
import beedLogo from '../images/beed-logo.png';
import btn from '../images/btn.png';

function Auctions() {
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(null);
  let [auctionsList, setAuctionsList] = useState([]);
  let [auctionStatus, setAuctionStatus] = useState('Open');

  useEffect(() => {
    fetchAuctionsList();
  }, [])

  useEffect(() => {
    fetchAuctionsList(auctionStatus === 'Closed')
  }, [auctionStatus]);

  function fetchAuctionsList(closed) {
    let url = `https://y5pvoa70yl.execute-api.us-east-1.amazonaws.com${closed ? '?Status=Closed' : ''}`;

    fetch(url)
      .then(res => res.json())
      .then(res => {
        setLoading(false);
        setAuctionsList(res);
      }, err => {
        setLoading(false);
        setError('Something went wrong. Please try again.');
      });
  }

  function handleAuctionStatusChange(status) {
    if (status !== auctionStatus){
      setAuctionStatus(status);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
  }

  function auctionStatusFilter() {
    return (
      <div className="toggle-filter">
        <span className={auctionStatus === 'Open' ? 'active' : null} onClick={handleAuctionStatusChange.bind(this, 'Open')}>Open</span>
        <span className={auctionStatus === 'Closed' ? 'active' : null} onClick={handleAuctionStatusChange.bind(this, 'Closed')}>Closed</span>
      </div>
    );
  }


  return (
    <div className="auctions-index container">
      <div className="auctions-index-header flex-container flex-align-center">
        <img src={beedLogo} height="55px" width="auto" />
        <div className="query-section flex-1 mb-0 ml-10 mr-10">
          <form onSubmit={handleSubmit}>
            <div className={`input-wrapper mb-0`}>
              <input type="text" placeholder="Search for anything" />
            </div>
            <div className="button-wrapper mb-0">
              <button className={`btn-link`}>
                <SearchIcon />
              </button>
            </div>
          </form>
        </div>
        <div>
          <p><Link to="/ledger" className="flex-container flex-align-center"><span className="mr-5">My BTN</span><img src={btn} width="20px" height="20px" /></Link></p>
        </div>
      </div>

      {
        loading ? <div className="mt-20"><Loading size="80" centered={true} /></div> :
          error ? <p className="mt-20">{error}</p> :
            !auctionsList.length ? <div className="mt-20"><p>There are currently no items for sale</p></div> :
              <div className="auctions-list-grid">
                {auctionsList.map((auction, i) => { return <AuctionGridItem key={i} auction={auction} />; })}
              </div>
      }
    </div>
  );
}

export default Auctions;




// <div className="auctions-filter-container">
//   <div className="auctions-filter-list">
//     <div className="auctions-filter">
//       <span className="auctions-filter-label">Status</span>
//       {auctionStatusFilter()}
//     </div>
//   </div>
// </div>
