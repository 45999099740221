function SearchIcon(props) {
  return (
    <svg viewBox="0 0 26 26">
      <path fill="none" strokeLinecap="round" strokeWidth="2"
        d="M17.6 17.6l6.3 6.3M2.2 11.2a9 9 0 1 0 18 0 9 9 0 1 0-18 0" />
    </svg>
  );
}

export default SearchIcon;
