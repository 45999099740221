import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import userpool from '../../userpool';

function SummaryAside() {
  let currentUser = userpool.getCurrentUser();
  let path = useLocation().pathname;

  return (
    <aside className="user-summary-aside">
      <p><Link to="/user/summary" className={path === '/user/summary' ? 'txt-bold active' : 'txt-bold'}>Summary</Link></p>
      <p><Link to="/user/wallet" className={path === '/user/wallet' ? 'txt-bold active' : 'txt-bold'}>Wallet</Link></p>
      <p><Link to="/user/selling" className={path === '/user/selling' ? 'txt-bold active' : 'txt-bold'}>Selling</Link></p>
    </aside>
  );
}

export default SummaryAside;
