import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Loading from '../loading';
import logo from '../../images/logo.png';
import userpool from '../../userpool';

function Sell() {
  let currentUser = userpool.getCurrentUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startingBid, setStartingBid] = useState('0');
  const [buyItNow, setBuyItNow] = useState('0');
  const [bidIncrement, setBidIncrement] = useState('0');

  useEffect(() => {
    if (loading) { submitForm(); }
  }, [loading]);


  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  function handleDescriptionChange(e) {
    setDescription(e.target.value);
  }

  function handleStartingBidChange(e) {
    setStartingBid(e.target.value);
  }

  function handleBuyItNowChange(e) {
    setBuyItNow(e.target.value);
  }

  function handleBidIncrementChange(e) {
    setBidIncrement(e.target.value);
  }

  function submitForm() {
    currentUser.getSession((_, session) => {
      fetch('https://y5pvoa70yl.execute-api.us-east-1.amazonaws.com',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': session.accessToken.jwtToken },
          body: JSON.stringify({ 'Title': title, 'Description': description, 'ReserveBid': startingBid, 'BuyItNow': buyItNow, 'BidIncrement': bidIncrement })
        }).then(res => res.json())
          .then(res => {
            setSuccess(res);
            setLoading(false);
          });
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
  }

  function disableSubmit() {
    let inputValue, floatValue;
    let inputs = [title, description, startingBid, buyItNow, bidIncrement];

    for (let i = 0; i < inputs.length; i++) {
      inputValue = inputs[i];
      if (inputValue === '') { return true; }
      floatValue = parseFloat(inputValue)
      if (floatValue !== NaN && floatValue < 1) { return true; }
    }

    return false;
  }

  if (loading) {
    return (
      <div className="sell-page container">
        <div className="sell-page-content">
          <h1 className="mb-30">Sell an Item</h1>
          <Loading size="80" />
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="sell-page container">
        <div className="sell-page-content">
          <h1 className="mb-30">List an Item</h1>
          <p className="mb-20">You've successfully listed your item for sale!</p>
          <p><Link to="/user/selling" className="btn-link">View your items</Link></p>
        </div>
      </div>
    );
  }


  return (
    <div className="sell-page container">
      <div className="sell-page-content">
        <h1 className="mb-30">List an Item</h1>
          <form>
            <div className="form-input-container">
              <label>Title</label>
              <input type="text" value={title} onChange={handleTitleChange} placeholder="Enter a general, but desctiptive title for this item" />
            </div>
            <div className="form-input-container">
              <label>Detailed Item Description</label>
              <textarea rows="5" value={description} onChange={handleDescriptionChange} placeholder="Enter a very destailed item description" />
            </div>
            <div className="form-input-container">
              <label>Year Created</label>
              <input type="text" placeholder="Enter year item was created" />
            </div>
            <div className="form-input-container">
              <label>Manufacturer or Creator</label>
              <input type="text" placeholder="Enter the Manufacturer or Creator of the item" />
            </div>
            <div className="form-input-container">
              <label>Model Number</label>
              <input type="text" placeholder={'Enter "n/a" if not applicable'} />
            </div>
            <div className="form-input-container">
              <label>Serial Number</label>
              <input type="text" placeholder={'Enter "n/a" if not applicable'} />
            </div>
            <div className="form-input-container">
              <label>Authentication Source</label>
              <input type="text" placeholder="If Authenticated, enter the source of the authentication" />
            </div>
            <div className="form-input-container">
              <label>Grading Source</label>
              <input type="text" placeholder="If Graded, enter the source of the grading" />
            </div>
            <div className="form-input-container">
              <label>Color</label>
              <input type="text" placeholder="List all primary colors" />
            </div>
            <div className="form-input-container">
              <label>Size</label>
              <input type="text" placeholder={'Enter "n/a" if not applicable'} />
            </div>
            <div className="form-input-container">
              <label>Original Defects</label>
              <input type="text" placeholder="List any original item defects (not from wear & tear or damages after manufacturing)" />
            </div>
            <div className="form-input-container">
              <label>Size</label>
              <input type="text" placeholder={'Enter "n/a" if not applicable'} />
            </div>
            <div className="form-input-container">
              <label>Modifications or Changes</label>
              <input type="text" placeholder={'Enter "n/a" if Not Applicable; include any damages, noticeable wear and tear, or significant aging done to the iteam'} />
            </div>

            <div className="form-input-container">
              <label>Starting Bid</label>
              <input type="number" value={startingBid} onChange={handleStartingBidChange} />
            </div>
            <div className="form-input-container">
              <label>Buy It Now Price</label>
              <input type="number" value={buyItNow} onChange={handleBuyItNowChange} />
            </div>
            <div className="form-input-container">
              <label>Bid Increment</label>
              <input type="number" value={bidIncrement} onChange={handleBidIncrementChange} />
            </div>
            <div className="form-input-container">
              <button className={`btn${disableSubmit() ? ' disabled' : ''}`} onClick={handleSubmit}>Submit Listing</button>
            </div>
          </form>
      </div>
    </div>
  );
}

export default Sell;
