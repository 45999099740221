import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SummaryAside from './summaryAside';
import AuctionImg from '../auctionImg';
import userpool from '../../userpool';
import { currentUserWon } from '../../util/misc';

function Wallet() {
  let currentUser = userpool.getCurrentUser();
  let [wallet, setWallet] = useState([{ ImageUrl: 'https://beed-auction-photos.s3.amazonaws.com/auction-114-barry-bonds-signed-jersey/Barry Bonds Jersey.jpg', btn: 'sgi-dmbiepz' },
    { ImageUrl: 'https://beed-auction-photos.s3.amazonaws.com/auction-116-shohei-ohtani-rookie-card-signed/Shohei Ohtani RC.jpg', btn: 'sgi-pwleink' }]);

  function walletItems() {
    let itemEls = wallet.map((item, i) => {
      return (
        <div className="wallet-item" key={item.btn}>
          <Link to={`/user/beed/${item.btn}/download-and-print`}>
            <div className="img-container auction-item-img-container">
              <AuctionImg auction={item} />
            </div>
          </Link>
          <p className="mt-10"><Link to={`/user/beed/${item.btn}/download-and-print`}>BTN: {item.btn}</Link></p>
        </div>
      );
    });

    return (
      <div className="wallet-item-grid">
        {itemEls}
      </div>
    );
  }

  return (
    <div className="user-wallet container">
      <h1 className="mb-0">{currentUser.username}</h1>
      <p className="mb-30"><Link to="/edit-profile" className="link-with-underline">Edit profile</Link></p>

      <div className="flex-container-lg">
        <SummaryAside />

        <div className="user-wallet-main">
          <h2 className="mb-20">Wallet</h2>
          {walletItems()}
        </div>
      </div>
    </div>
  );
}

export default Wallet;
