import React, { useState, useEffect, useRef } from 'react';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import AuctionImg from './auctionImg';
import Loading from './loading';
import ClearInput from './svg/clearInput';
import SearchIcon from './svg/searchIcon';
import QRCode from '../images/qr.jpg';
import beedLogo from '../images/beed-logo.png';
import btn from '../images/btn.png';
import charizard from '../images/charizard-front.jpg';

function Ledger() {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [itemLedger, setItemLedger] = useState(null);
  const inputEl = useRef(null);
  let params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    if (loading) { fetchLedger(); }
  }, [loading]);


  function fetchLedger() {
    fetch(`https://ld36mopxq6k7j4mfiaatfdjp7a0mnezg.lambda-url.us-east-1.on.aws/?btn=sgi-ctntbnz`)
      .then(res => res.json())
      .then(res => {
        setLoading(false);

        if (res.error) {
          setError(res.message);
        } else {
          setItemLedger(res);
        }
      });
  }

  function handleQueryChange(e) {
    setQuery(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();

    // if (searchParams.get('query') === query) {
      queryObject();
    // } else {
      // setSearchParams({ query: query }, { replace: true });
    // }
  }

  function queryObject() {
    setLoading(true);
    setItemLedger(null);
    setError(null);
  }

  function clearQuery(e) {
    handleQueryChange({ target: { value: '' } });
    inputEl.current.focus();
  }

  // if (getError) {
  //   return (
  //     <div className="buy-it-now container">
  //       <div className="buy-it-now-content">
  //         <h1 className="mb-30">Buy It Now</h1>
  //         <p>{getError}</p>
  //       </div>
  //     </div>
  //   );
  // }



  return (
    <div className="ledger container">
      <div className="ledger-header flex-container flex-align-center">
        <img src={beedLogo} height="55px" width="auto" />
        <h3 className="txt-center flex-1">BEED™ TOKEN NUMBER (BTN) LOOKUP & LEDGER VIEW</h3>
      </div>
      <div className="ledger-content">
        <section className="query-section">
          <form onSubmit={handleSubmit}>
            <div className={`input-wrapper${query.length ? ' active' : ''}`}>
              <input type="text" ref={inputEl} value={query} onChange={handleQueryChange} placeholder="Enter BTN here" />
              <ClearInput clickHandler={clearQuery} />
            </div>
            <div className="button-wrapper">
              <button className={`btn-link${!query.length || loading ? ' disabled' : ''}`}>
                <SearchIcon />
              </button>
            </div>
          </form>
        </section>

        {!loading ? null : <Loading size="80" centered={true} />}

        {
          !itemLedger ? null :
          <section className="result-section">
            <div className="flex-container flex-align-center mb-30">
              <div className="qr-container">
                <img src={QRCode} width="150px" height="150px" />
              </div>
              <div className="ml-20">
                <h3>BTN #<img src={btn} width="20px" height="20px" /> sgi-ctntbnz</h3>
              </div>
            </div>

            <div>
              <h2 className="txt-center mb-20">TRANSACTION LEDGER VIEW*</h2>

              <div className="flex-container flex-align-center mb-30">
                <div>
                  <img src={charizard} width="200px" height="auto" />
                </div>
                <div className="ml-20">
                  <p className="mb-20">Charizard V from the VSTAR Universe - s12a Gem Mint 10</p>
                  <p>View item details <a className="link-with-permanent-underline" href="#">here</a></p>
                </div>
              </div>

              <table className="transaction-ledger-table">
                <thead><tr><td><h4>DATE</h4></td><td><h4>BLOCKCHAIN LEDGER ENTRY</h4></td><td><h4>PRICE</h4></td></tr></thead>
                <tbody>
                  <tr>
                    <td>6-Aug-20</td>
                    <td>
                      <p>CREATION DATE</p>
                      <p>ITEM ORIGINATOR: @ThePokemonCompany</p>
                    </td>
                    <td>$8.99</td>
                  </tr>
                  <tr>
                    <td>12-Dec-20</td>
                    <td>
                      <p>BEED TRANSFERRED</p>
                      <p>TO USER: @PokeCollector888</p>
                      <p>PURCHASE FROM: @ThePokemonCompany</p>
                      <p>Transaction #416682 via www.pokemon.com</p>
                    </td>
                    <td>$15</td>
                  </tr>
                  <tr>
                    <td>21-Dec-20</td>
                    <td>
                      <p>IMAGE UPLOADED</p>
                      <p>TO USER: @PokeCollector888</p>
                      <p>IMAGE TYPE: CERTIFICATE OF AUTHENTICITY</p>
                    </td>
                    <td>$20</td>
                  </tr>
                </tbody>
              </table>

            </div>

          </section>
        }

      </div>
    </div>
  );
}

export default Ledger;
