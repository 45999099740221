import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import SummaryAside from './summaryAside';
import QRCode from '../../images/qr.jpg';
import BackIcon from '../svg/back';
import userpool from '../../userpool';
import btn from '../../images/btn.png';

function DownloadAndPrint() {
  let currentUser = userpool.getCurrentUser();
  let params = useParams();

  return (
    <div className="download-and-print container">
      <h1 className="mb-0">{currentUser.username}</h1>
      <p className="mb-30"><Link to="/edit-profile" className="link-with-underline">Edit profile</Link></p>

      <div className="flex-container-lg">
        <SummaryAside />

        <div className="dl-print-main">
          <p className="mb-10"><Link className="link-with-underline" to="/user/wallet"><span className="mr-5"><BackIcon /></span>Back to wallet</Link></p>
          <h1 className="mb-20">Download & print</h1>

          <p className="mb-20">Beeds™ can be printed and affixed to physical items directly on packaging,
            certificates of authenticity, official paperwork, and receipts.  The token
            code is yours, so you can use it as you see fit.  They can also be printed
            on cards that represent a verifiable chain of ownership for anyone to lookup
            on their phones when they see an item.
          </p>

          <div className="mb-30">
            <div className="flex-container flex-align-center mb-20">
              <div className="qr-container">
                <img src={QRCode} width="300px" height="300px" />
              </div>
              <div className="ml-40">
                <div className="mb-20">
                  <Link to="#" className="btn-link">Download as .pdf</Link>
                </div>
                <div>
                  <Link to="#" className="btn-link">Download as .jpg</Link>
                </div>
              </div>
            </div>
            <div>
              <h4 className="txt-bold">BTN <img src={btn} width="20px" height="20px" /></h4>
              <h4 className="txt-bold">{params.id}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadAndPrint;
